var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "mainContainer" }, [
        _c("div", {
          staticClass: "bgImgContainer",
          style: {
            "background-image": "url(" + _vm.imgSrc + ")",
            "background-size": "cover",
          },
        }),
        _c("div", { staticClass: "login-container" }, [
          _c(
            "div",
            { staticStyle: { display: "flex", "justify-content": "center" } },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    width: "50%",
                    height: "450px",
                    "background-color": "rgba(255, 255, 255, .9)",
                    "border-radius": "4px",
                    "box-shadow": "0px 2px 14px 0px rgba(0, 0, 0, 0.1)",
                  },
                },
                [
                  _c("div", { staticClass: "title-container" }, [
                    _c("div", [
                      _c("img", {
                        staticStyle: {
                          width: "100%",
                          height: "100%",
                          "max-width": "400px",
                          padding: "10px 20px",
                        },
                        attrs: { src: _vm.logo },
                      }),
                    ]),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "font-size": "14pt",
                          "line-height": "35px",
                        },
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.title))])]
                    ),
                  ]),
                  _c(
                    "el-form",
                    {
                      ref: "loginForm",
                      staticClass: "login-form",
                      attrs: {
                        model: _vm.loginForm,
                        rules: _vm.loginRules,
                        "auto-complete": "on",
                        "label-position": "left",
                      },
                    },
                    [
                      _vm.allowRelationDealerLogin
                        ? _c(
                            "div",
                            {
                              staticClass: "checkOrg",
                              staticStyle: { "margin-bottom": "8px" },
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-right": "5px",
                                    color: "#606266",
                                  },
                                },
                                [_vm._v("业务端：")]
                              ),
                              _c(
                                "el-radio-group",
                                {
                                  on: { change: _vm.bizTypeChange },
                                  model: {
                                    value: _vm.bizType,
                                    callback: function ($$v) {
                                      _vm.bizType = $$v
                                    },
                                    expression: "bizType",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio",
                                    { attrs: { label: "DEALER" } },
                                    [_vm._v("经销商")]
                                  ),
                                  _c(
                                    "el-radio",
                                    { attrs: { label: _vm.originBizType } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.originBizType == "GROUP"
                                            ? "经销商集团"
                                            : _vm.originBizTypeDisplay
                                        )
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "div",
                        { staticStyle: { "padding-top": "5px" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { prop: "account" } },
                            [
                              _c(
                                "el-input",
                                {
                                  ref: "account",
                                  attrs: {
                                    maxlength: "40",
                                    placeholder: "用户名",
                                    name: "account",
                                    type: "text",
                                    tabindex: "1",
                                    "auto-complete": "on",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.loginForm.account,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.loginForm,
                                        "account",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loginForm.account",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _c("i", {
                                      staticClass: "el-icon-user",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { prop: "password" } },
                            [
                              _c(
                                "el-input",
                                {
                                  key: _vm.passwordType,
                                  ref: "password",
                                  attrs: {
                                    maxlength: "40",
                                    type: _vm.passwordType,
                                    placeholder: "密码",
                                    name: "password",
                                    tabindex: "2",
                                    "auto-complete": "on",
                                    clearable: "",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.handleLogin.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                  model: {
                                    value: _vm.loginForm.password,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.loginForm,
                                        "password",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "loginForm.password",
                                  },
                                },
                                [
                                  _c("template", { slot: "prepend" }, [
                                    _c("i", {
                                      staticClass: "el-icon-unlock",
                                      staticStyle: { "font-size": "16px" },
                                    }),
                                  ]),
                                  _c("template", { slot: "append" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "show-pwd",
                                        on: { click: _vm.showPwd },
                                      },
                                      [
                                        _c("svg-icon", {
                                          attrs: {
                                            "icon-class":
                                              _vm.passwordType === "password"
                                                ? "eye"
                                                : "eye-open",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticStyle: { "margin-bottom": "5px" },
                              attrs: { prop: "verificationCode" },
                            },
                            [
                              _c(
                                "div",
                                { staticStyle: { display: "flex" } },
                                [
                                  _c("el-input", {
                                    staticStyle: { width: "60%" },
                                    attrs: {
                                      maxlength: "40",
                                      placeholder: "请输入验证码",
                                      clearable: "",
                                    },
                                    nativeOn: {
                                      keyup: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.handleLogin.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                    model: {
                                      value: _vm.loginForm.verificationCode,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.loginForm,
                                          "verificationCode",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "loginForm.verificationCode",
                                    },
                                  }),
                                  _c("div", { staticClass: "codeImgBox" }, [
                                    _vm.verifyCodeId
                                      ? _c("img", {
                                          staticStyle: {
                                            width: "80%",
                                            height: "30px",
                                          },
                                          attrs: {
                                            src:
                                              "data:image/jpg;base64," +
                                              _vm.codeImg,
                                            alt: "",
                                          },
                                        })
                                      : _c("img", {
                                          staticStyle: {
                                            width: "80%",
                                            height: "30px",
                                          },
                                          attrs: {
                                            src: require("@/assets/images/404.png"),
                                            alt: "",
                                          },
                                        }),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-refresh refreshButton",
                                      on: { click: _vm.changeCodeImg },
                                    }),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "flex",
                                "justify-content": "flex-end",
                                "margin-top": "-5px",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  style: { color: _vm.themeColor },
                                  attrs: { type: "text" },
                                  on: { click: _vm.resetPassword },
                                },
                                [_vm._v("忘记密码")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          style: {
                            width: "100%",
                            "margin-top": "5px",
                            "background-color": _vm.themeColor,
                            border: "1px solid " + _vm.themeColor,
                          },
                          attrs: {
                            size: "small",
                            loading: _vm.loading,
                            round: "",
                            type: "primary",
                          },
                          nativeOn: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.handleLogin.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("登录")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _c(
          "div",
          {
            staticStyle: {
              position: "absolute",
              top: "97%",
              color: "white",
              display: "flex",
              "justify-content": "center",
              width: "100%",
              "font-size": "15px",
            },
          },
          [
            _vm.icpNo
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      "text-shadow": "0 1px 3px rgba(0,0,0,0.15)",
                      color: "#fff",
                    },
                  },
                  [
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#fff",
                          "text-decoration": "underline",
                        },
                        attrs: {
                          href: "https://beian.miit.gov.cn",
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.icpNo))]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.psbNo
              ? _c(
                  "span",
                  {
                    staticStyle: {
                      "text-shadow": "0 1px 3px rgba(0,0,0,0.15)",
                      color: "#fff",
                      "padding-left": "5px",
                    },
                  },
                  [
                    _vm._v("| "),
                    _c("img", {
                      staticStyle: {
                        width: "13px",
                        height: "13px",
                        "margin-right": "3px",
                      },
                      attrs: { src: require("@/assets/images/recordImg.png") },
                    }),
                    _c(
                      "a",
                      {
                        staticStyle: {
                          color: "#fff",
                          "text-decoration": "underline",
                        },
                        attrs: {
                          href:
                            "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=" +
                            _vm.psbNo,
                          target: "_blank",
                        },
                      },
                      [_vm._v(_vm._s(_vm.psbNo))]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ]),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPwdDialog,
            "close-on-click-modal": false,
            title: "修改密码",
            width: "480px",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPwdDialog = $event
            },
            close: _vm.closePwd,
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("修改密码")]
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "app-cls", staticStyle: { "max-height": "500px" } },
            [
              _c(
                "div",
                {
                  ref: "header",
                  staticClass: "header-cls",
                  staticStyle: { "max-height": "500px", overflow: "auto" },
                },
                [
                  _c(
                    "el-card",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.pwdLoading,
                          expression: "pwdLoading",
                        },
                      ],
                      staticStyle: { padding: "5px 20px" },
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "formPwd",
                          attrs: {
                            model: _vm.formPwd,
                            rules: _vm.formRules,
                            "label-width": "100px",
                          },
                        },
                        [
                          _c(
                            "el-row",
                            [
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "用户名：",
                                        prop: "userName",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          placeholder: "请输入用户名",
                                          clearable: "",
                                          disabled: _vm.firstLogin,
                                        },
                                        model: {
                                          value: _vm.formPwd.userName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formPwd,
                                              "userName",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formPwd.userName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "旧密码：",
                                        prop: "oldPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入旧密码",
                                          "show-password": "",
                                          clearable: "",
                                          autocomplete: "new-password",
                                        },
                                        model: {
                                          value: _vm.formPwd.oldPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formPwd,
                                              "oldPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formPwd.oldPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "新密码：",
                                        prop: "newPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入新密码",
                                          "show-password": "",
                                          clearable: "",
                                          autocomplete: "new-password",
                                        },
                                        model: {
                                          value: _vm.formPwd.newPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formPwd,
                                              "newPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formPwd.newPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "确认密码：",
                                        prop: "confirmPassword",
                                      },
                                    },
                                    [
                                      _c("el-input", {
                                        attrs: {
                                          maxlength: "40",
                                          type: "password",
                                          placeholder: "请输入确认密码",
                                          "show-password": "",
                                          clearable: "",
                                          autocomplete: "new-password",
                                        },
                                        model: {
                                          value: _vm.formPwd.confirmPassword,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.formPwd,
                                              "confirmPassword",
                                              typeof $$v === "string"
                                                ? $$v.trim()
                                                : $$v
                                            )
                                          },
                                          expression: "formPwd.confirmPassword",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-col",
                                { attrs: { span: 24 } },
                                [
                                  _c(
                                    "el-form-item",
                                    {
                                      attrs: {
                                        label: "验证码：",
                                        prop: "verificationCode",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { display: "flex" } },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "60%" },
                                            attrs: {
                                              maxlength: "40",
                                              placeholder: "请输入验证码",
                                              clearable: "",
                                            },
                                            model: {
                                              value:
                                                _vm.formPwd.verificationCode,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.formPwd,
                                                  "verificationCode",
                                                  typeof $$v === "string"
                                                    ? $$v.trim()
                                                    : $$v
                                                )
                                              },
                                              expression:
                                                "formPwd.verificationCode",
                                            },
                                          }),
                                          _c(
                                            "div",
                                            { staticClass: "codeImgBox" },
                                            [
                                              _vm.verifyCodeId
                                                ? _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src:
                                                        "data:image/jpg;base64," +
                                                        _vm.codeImg,
                                                      alt: "",
                                                    },
                                                  })
                                                : _c("img", {
                                                    staticStyle: {
                                                      width: "80%",
                                                      height: "30px",
                                                    },
                                                    attrs: {
                                                      src: require("@/assets/images/404.png"),
                                                      alt: "",
                                                    },
                                                  }),
                                              _c("i", {
                                                staticClass:
                                                  "el-icon-refresh refreshButton",
                                                on: {
                                                  click: _vm.changeCodeImg,
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticStyle: {
                    display: "flex",
                    "justify-content": "center",
                    "margin-top": "8px",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", icon: "el-icon-close" },
                      on: { click: _vm.closePwd },
                    },
                    [_vm._v(" 关闭 ")]
                  ),
                  _c(
                    "el-button",
                    {
                      style: {
                        "background-color": _vm.themeColor,
                        border: "1px solid " + _vm.themeColor,
                      },
                      attrs: {
                        size: "mini",
                        type: "primary",
                        icon: "el-icon-check",
                      },
                      on: { click: _vm.modifyPwd },
                    },
                    [_vm._v(" 确认 ")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showPesetPasswordDialog,
            "close-on-click-modal": false,
            width: "800px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPesetPasswordDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("忘记密码")]
              ),
            ]
          ),
          _c("PesetPasswordDialog", {
            attrs: { refresh: _vm.showPesetPasswordDialog },
            on: { closePage: _vm.closePesetPasswordDialog },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          staticClass: "pageDialog",
          attrs: {
            visible: _vm.showTfaVerifyDialog,
            "close-on-click-modal": false,
            width: "500px",
            "append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showTfaVerifyDialog = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c(
                "span",
                { staticStyle: { "font-size": "15px", "font-weight": "700" } },
                [_vm._v("二次认证")]
              ),
            ]
          ),
          _c("TfaVerifyDialog", {
            attrs: {
              refresh: _vm.showTfaVerifyDialog,
              tfaVerifyData: _vm.tfaVerifyData,
              deviceId: _vm.loginForm.deviceId,
            },
            on: { closePage: _vm.closeTfaVerifyDialog },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }