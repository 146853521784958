"use strict";

var _interopRequireWildcard = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireWildcard.js").default;
var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
require("core-js/modules/es.array.filter.js");
require("core-js/modules/es.array.splice.js");
require("core-js/modules/es.object.keys.js");
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/web.dom-collections.for-each.js");
require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_core-js@3.38.1@core-js/modules/es.array.iterator.js");
require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_core-js@3.38.1@core-js/modules/es.promise.js");
require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_core-js@3.38.1@core-js/modules/es.object.assign.js");
require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_core-js@3.38.1@core-js/modules/es.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
var _vant = _interopRequireDefault(require("vant"));
require("vant/lib/index.css");
require("e-icon-picker/lib/symbol.js");
require("e-icon-picker/lib/index.css");
require("element-ui/lib/theme-chalk/icon.css");
require("@/styles/element-variables.scss");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
require("./icons");
require("./utils/error-log");
var filters = _interopRequireWildcard(require("./filters"));
var _lodash = _interopRequireDefault(require("lodash"));
var _versionUpdate = _interopRequireDefault(require("./utils/versionUpdate"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _watermark = require("@/utils/watermark");
var _common = require("@/utils/common");
//基本彩色图标库
// 基本样式，包含基本图标
// import 'font-awesome/css/font-awesome.min.css'; //font-awesome 图标库
//element-ui 图标库

_vue.default.use(_elementUi.default, {
  size: 'small',
  zIndex: 3000
});
_vue.default.use(_vant.default);
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.prototype._ = _lodash.default;
_vue.default.prototype.$Bus = new _vue.default();
_vue.default.config.productionTip = false;
window.$store = _store.default;
var router = new _vueRouter.default({
  base: process.env.VUE_APP_ROOT_PATH,
  mode: 'history',
  routes: _router.default
});
var whiteList = ['/servicePack/informationCheck', '/signConfirm', '/ssoLogin', '/signedPage', '/collectConsignmentInfo', '/electronicContractPlatform/sign', '/electronicContractPlatform/preview', '/electronicContractPlatform/download', '/electronicContractPlatform/signatureBoard', '/jumpPage'];

//解决三级菜单keep-alive缓存失效
router.beforeResolve(function (to, from, next) {
  if (to.matched && to.matched.length > 2) {
    to.matched.splice(1, to.matched.length - 2);
  }
  next();
});
router.beforeEach(function (to, from, next) {
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  _versionUpdate.default.isNewVersion();
  if (to.query && to.query.bizType) sessionStorage.setItem('Q_BizType', to.query.bizType);
  if (to.path == '/login' && whiteList.indexOf(from.path) != -1) {
    next(false);
  } else {
    var portalHomePath = localStorage.getItem('portalHomePath');
    if (to.path == '/login' && portalHomePath && from.path == portalHomePath) {
      next(false);
    } else {
      next();
    }
  }
});
// 初始化主应用
new _vue.default({
  router: router,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  },
  watch: {
    '$route': function $route(newVal, oldVal) {
      //监听路由是否变化
      //设置系统主题色
      if (localStorage.getItem('themeColor')) {
        var themeColor = localStorage.getItem('themeColor');
        _store.default.dispatch('settings/changeSetting', {
          key: 'theme',
          value: themeColor
        });
      }
      var userInfo = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;
      if (userInfo && userInfo.user) {
        (0, _watermark.setWaterMark)((userInfo.user.account || '') + '@' + (userInfo.user.userName || ''), userInfo.data && userInfo.data.orgName ? userInfo.data.orgName : '', (0, _common.parseTime)(new Date(), '{y}{m}{d}{h}'));
      } else {
        (0, _watermark.removeWatermark)();
      }
    }
  }
}).$mount('#app');