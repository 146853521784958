"use strict";

var _interopRequireDefault = require("/var/jenkins_home/workspace/uat-ui-web-integration/node_modules/_@babel_runtime@7.25.6@@babel/runtime/helpers/interopRequireDefault.js").default;
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es.object.to-string.js");
require("core-js/modules/es.regexp.exec.js");
require("core-js/modules/es.regexp.to-string.js");
require("core-js/modules/es.string.replace.js");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = require("element-ui");
var _router = _interopRequireDefault(require("@/router"));
var _store = _interopRequireDefault(require("../store"));
// 创建axios实例
var service = _axios.default.create({
  // baseURL: "", // api 的 base_url
  timeout: 3 * 60000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(function (config) {
  var token = localStorage.getItem('token') || "";
  config.headers['Authorization'] = token.replace(/"/g, "");
  var portalId = localStorage.getItem('portalId');
  config.headers['Access-Control-Allow-Origin'] = "*";
  config.headers['x-nirvana-portalId'] = parseInt(portalId);
  return config;
}, function (error) {
  // Do something with request error
  console.log(error); // for debug
  Promise.reject(error);
});

// response 拦截器
service.interceptors.response.use(function (response) {
  //网络请求响应
  if (response.status != 200) {
    var _errMsg = response.msg;
    (0, _elementUi.Message)({
      message: _errMsg,
      type: 'error',
      duration: 5 * 1000
    });
    return response;
  } else {
    var res = response.data;
    if (res.code == undefined) {
      //文件下载，没有code
      return res;
    } else {
      var requestUrl = response.request.responseURL;
      if (res.code != 200) {
        var _errMsg2 = res.msg;
        if (_errMsg2 && res.code != '401' && res.code != '403' && res.code != '40301' && res.code != '400114') {
          (0, _elementUi.Message)({
            message: _errMsg2,
            type: 'error',
            duration: 5 * 1000
          });
        }
        if (res.code == '401' || res.code == '403') {
          if (requestUrl && requestUrl.indexOf('/token/refresh') == -1 && requestUrl.indexOf('/suppliers/getByType') == -1) {
            (0, _elementUi.Message)({
              dangerouslyUseHTMLString: true,
              message: res.code == '401' ? "您的登录状态已过期，请重新登录！" : _errMsg2,
              type: 'warning',
              duration: 5 * 1000,
              customClass: 'zZindex'
            });
          }
          if (res.code == '401') {
            _store.default.dispatch('user/logout');
            _store.default.dispatch('tagsView/delAllCachedViews', _router.default);
            _router.default.toString().replace({
              path: '/login'
            });
          }
        }
      }
      return res;
    }
  }
}, function (error) {
  var errResponse = error.response;
  if (errResponse) {
    if (errResponse.status == '401') {
      errMsg = "登录认证失败，请重新登录！";
      _store.default.dispatch('user/logout');
      _store.default.dispatch('tagsView/delAllCachedViews', _router.default);
      _router.default.replace({
        path: '/login'
      });
    }
  }
  return Promise.reject(error);
});
var _default = exports.default = service;